<template>
  <div class="header w-100vw" :style="'background: url('+bannerSrc+') no-repeat center; background-size: cover;'">
    <!-- <img class="banner" :src="bannerSrc" alt="" /> -->
    <div class="nav w-1400px">
      <img class="logo" src="../assets/logo.png" alt="">
      <!-- <router-link :to="{name:item.link}" > -->
        <div v-for="(item,index) in navList" :key="index" :class="[{'on':navCur==index},'item']" @click.stop="changeNav(index,item.link)">{{item.name}}</div>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      navList:[],
      navCur: "",
      bannerSrc:""
    }
  },
  created () {
    this.navList = this.$store.state.navList;
    this.navCur=this.$store.state.navCur;
    this.bannerSrc = require(`../assets/banner/banner${this.navCur}.png`)
  },
  computed: {
    watchNavCur() {
      return this.$store.state.navCur;
    }
  },
  watch: {
    watchNavCur(newValue, oldValue) {
      this.navCur=newValue;
      this.bannerSrc = require(`../assets/banner/banner${newValue}.png`)
    }
  },
  methods: {
    changeNav(index,link) {
      this.navCur=index;
      this.$store.commit('changeNavCur', index);
      this.bannerSrc = require(`../assets/banner/banner${index}.png`)
      this.$router.push({ name: link })
    }
  },
};
</script>

<style scoped lang="scss">
.header {
   width: 100vw;
   height: 80vh;  
   background-size: cover!important;
  .nav {
    height: 40px;
    position: absolute;
    left: 0;
    top: 30px;
    right: 0;
    margin: auto;
    .logo{
      width: 86px;
      margin-left: 10px;
      margin-right: 280px;
    }
    .item{
      font-size: 20px;
      color: #fff;
      margin: 0 58px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid rgba(255,255,255,0);
      transition: all .25s linear;
      &:hover{
        font-weight: bold;
        cursor: pointer;
        border-bottom: 1px solid #fff;
      }
      &.on{
        font-weight: bold;
        cursor: pointer;
        border-bottom: 1px solid #fff;
      }
    }
  }
}
</style>
