<template>
  <div class="footer w-100vw">
    <div class="footContent w-1400px">
      <div class="footNavList">
        <div class="footNavItem">
          <h3>快速导航</h3>
          <router-link :to="{name:item.link}" v-for="(item,index) in navList" :key="index">
            <div class="item" @click="changeNav(index)">{{item.name}}</div>
          </router-link>
        </div>
        <div class="footNavItem">
          <h3>产品与服务</h3>
          <div @click="linkToProductsServices(item.link)" class="item" v-for="(item,index) in navList2" :key="index">{{item.name}}</div>
        </div>
        <div class="footNavItem">
          <h3>解决方案</h3>
          <div @click="linkToSolution(item.link)" class="item" v-for="(item,index) in navList3" :key="index">{{item.name}}</div>
        </div>
      </div>
      <div class="contact">
        <img src="../assets/logo.png" alt="" srcset="">
        <div class="item">长三角一体化示范区（上海）在赢端网络科技有限公司</div>
        <div class="item">公司总机：021-67869265</div>
        <div class="item">客服咨询：400-0218-115</div>
        <div class="item">公司地址：上海市闵行区申虹路958弄C座301</div>
        <div class="item">商务邮箱：service@kuailiwu.com</div>
      </div>
      <div class="qrcode">
        <div>
          <img src="../assets/footer/qrcode1.png" alt="" srcset="">
          <p>微信公众号</p>
        </div>
        <div>
          <img src="../assets/footer/qrcode2.png" alt="" srcset="">
          <p>快礼物商城</p>
        </div>
      </div>
    </div>

    <p class="copyright">{{ REGISTER_SYSTEM_INFO_data[0].text }} <a :href="REGISTER_SYSTEM_INFO_data[0].infoA.url"
        target="_blank">{{ REGISTER_SYSTEM_INFO_data[0].infoA.text }}</a> <a
        :href="REGISTER_SYSTEM_INFO_data[0].infoB.url" target="_blank">{{ REGISTER_SYSTEM_INFO_data[0].infoB.text }}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      REGISTER_SYSTEM_INFO_data: [
        {
          hostName: "",
          text: "",
          infoA: {
            url: "",
            text: ""
          },
          infoB: {
            url: "",
            text: ""
          }
        },
      ],
      navList:[],
      navList2:[
        {name:"权益商品供应",link:"4"},
        {name:"策划营销服务",link:"1"},
        {name:"数字场景开发",link:"2"},
        {name:"平台流量运营",link:"3"}
      ],
      navList3:[
        {name:"解决营销关键点",link:"5"},
        {name:"用户精细化营销",link:"1"},
        {name:"打造场景金融生态圈",link:"2"},
        {name:"企业数字化营销",link:"3"},
        {name:"服务保障",link:"4"}
      ]
    }
  },
  created() {
    this.navList = this.$store.state.navList;
    this.addJs("https://www.asmkt.com/registerSystemInfo.js").then(() => {
      let hostName = window.location.hostname;
      let filterREGISTER_SYSTEM_INFO_data = REGISTER_SYSTEM_INFO.filter(item => {
        return item.hostName == hostName;
      })
      if(filterREGISTER_SYSTEM_INFO_data.length){
        this.REGISTER_SYSTEM_INFO_data = filterREGISTER_SYSTEM_INFO_data
      }
    })
  },
  methods: {
    addJs(url) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = url
        script.type = 'text/javascript'
        document.body.appendChild(script)
        script.onload = () => {
          resolve()
        }
      })
    },
    changeNav(index) {
      window,scrollTo(0,0)
      this.$store.commit('changeNavCur', index);
    },
    linkToProductsServices(parmas){
      if( this.$router.app._route.name=="ProductsServices"){
        this.$store.commit('changeProductsServicesTo', parmas);
        return
      }
      this.$store.commit('changeNavCur', 1);
      this.$router.push({ name: 'ProductsServices', params: { to: parmas }})
      window,scrollTo(0,0)
    },
    linkToSolution(parmas){
      console.log('[ PARMAS ] >', parmas)
      if( this.$router.app._route.name=="Solution"){
        this.$store.commit('changeSolutionTo', parmas);
        return
      }
      this.$store.commit('changeNavCur', 1);
      this.$router.push({ name: 'Solution', params: { to: parmas }})
      window,scrollTo(0,0)
    }
  },
}
</script>

<style scoped lang="scss">
.footer{
  position: relative;
  width: 100vw;
  height: 300px;
  font-size: 14px;
  background: #4c4948;
  .footContent{
    padding: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footNavList{
      display: flex; justify-content: flex-start;
      .footNavItem{
        width: 160px;
        display: flex;
        flex-direction: column;
        div{
          cursor: pointer;
        }
        h3{
          color: #fff;
          height: 36px;
          display: flex;
          align-items: center;
          }
        .item{
          width: 100%;
          height: 35px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #a6a6a6;
          &:hover{
            color: #fff;
            transition: all .25s linear;
          }
        }
      }
    }
    .contact{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      img{
        width: 86px;
        margin-bottom: 10px;
      }
      .item{
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #a6a6a6;
      }
    }
    .qrcode{
      width: 342px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        width: 160px;
        height: 160px;
      }
      p{
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        color: #fff;
       }
    }
  }
  .copyright{
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: #a6a6a6 !important;
    a{
      color: #a6a6a6;
    }
  }
}
</style>
