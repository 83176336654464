import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import NewHome from '../views/NewHome/NewHome.vue'


Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'NewHome',
    component: NewHome,
    meta:{
      title:"在赢端 数字场景权益服务商"
    }
  },
  {
    path: '/ProductsServices/ProductsServices',
    name: 'ProductsServices',
    meta: {
      title: "在赢端 产品与服务"
    },
    component: () => import( /* webpackChunkName: "ProductsServices" */ '../views/ProductsServices/ProductsServices.vue')
  },
  {
    path: '/Solution/Solution',
    name: 'Solution',
    meta: {
      title: "在赢端 解决方案"
    },
    component: () => import( /* webpackChunkName: "Solution" */ '../views/Solution/Solution.vue')
  },
  {
    path: '/Cooperation/Cooperation',
    name: 'Cooperation',
    meta: {
      title: "在赢端 合作联系"
    },
    component: () => import( /* webpackChunkName: "Cooperation" */ '../views/Cooperation/Cooperation.vue')
  },
  {
    path: '/About/About',
    name: 'About',
    meta: {
      title: "在赢端 关于我们"
    },
    component: () => import( /* webpackChunkName: "About" */ '../views/About/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router