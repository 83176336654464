<template>
  <div id="app" v-if="showApp">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      showApp: false
    }
  },
  mounted () {
     if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          window.location.href="https://www.asmkt.com/mobile/#/"
      }
      else {
         this.showApp = true
      }    
  }
}
</script>
<style lang="scss">
.w-1400px{
  width: 1400px;
  margin: 0 auto;
}
.w-100vw{
  width: 100vw;
  display: flex;
  justify-content: center;
}
</style>
