<template>
  <div class="home">
    <div class="f1 w-1400px">
      <div class="text0">
        <div class="text text1">
          <h1>山川形胜 金城千里</h1>
          <p>足以控四夷，制天下</p>
        </div>
        <div class="text text2">
          <h1>数字场景权益服务商</h1>
          <p>助您布局商业 智胜天下</p>
        </div>
      </div>
    </div>

    <!-- <div class="f2 w-100vw">
      <div class="f2-content">
        <div class="slideText">
          <div :class="[{cur:swiperTextActive==index},'itemText']" v-for="(item,index) in swiperData" :key="index" @click="changeSlideCur(index)">
            {{item.text}}
          </div>
        </div>
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item,index) in swiperData" :key="index" :style="'background: url('+item.img+') no-repeat center; background-size: cover;'">
          </swiper-slide>
        </swiper>
      </div>       
    </div> -->

    <div class="f3 w-100vw">
      <div class="f3-content">
        <div class="f3_left" data-aos="fade-right">
          <p>[ ASMKT在赢端是谁？]</p>
          <h3>金融行业</h3>
          <h2>创新数字科技公司</h2>
          <span style="font-size:22px;line-height:30px">专注于解决企业用户<br>及业务短长期的增长痛点</span>
          <img src="../../assets/NewHome/arrowDown.png" alt="">
        </div>
        <div class="f3_right" data-aos="fade-left">
          <img src="../../assets/NewHome/10.png" alt="">
          <ul style="width: 690px;">
            <li>
              <h3><countTo class="num" ref='countNumRef' :startVal='0' :endVal='10' :duration='3000'></countTo>+</h3>
              <p>总行战略合作</p>
            </li>
            <li>
              <h3><countTo class="num" ref='countNumRef' :startVal='0' :endVal='100' :duration='3000'></countTo>+</h3>
              <p>行业客户</p>
            </li>
            <li>
              <h3><countTo class="num" ref='countNumRef' :startVal='0' separator=""  :endVal='50' :duration='3000'></countTo><span>亿</span>+</h3>
              <p>交易额</p>
            </li>
            <li>
              <h3><countTo class="num" ref='countNumRef' :startVal='0' separator=""  :endVal='1000' :duration='3000'></countTo>+</h3>
              <p>品牌供应</p>
            </li>
            <li>
              <h3><countTo class="num" ref='countNumRef' :startVal='0' :endVal='8' :duration='3000'></countTo><span>亿</span>+</h3>
              <p>累计服务客户</p>
            </li>
          </ul>
        </div>
        <div class="f3_bottom" data-aos="zoom-in">
          帮助银行与品牌构建金融级流量枢纽，赋能客户深度经营。<br/>帮助品牌最小成本的获得流量支配权，为企业构建未来数字化护城墙！
        </div>
      </div>      
    </div>

   

    <div class="f5">
      <div class="f5-content">
        <div class="title" data-aos="fade-up">
          <h2>基于SAAS系统的用户一站式经营平台</h2>
          <p>实现用户获新引入、经验及贡献值养成、使用习惯培<br/>养、定向奖励、权益及服务核销全流程。</p>
        </div>
        <img data-aos="fade-left" src="../../assets/NewHome/f5-01.png" alt="">
        <ul data-aos="fade-right">
          <li>
            <h3>海量权益商品</h3>
            <p>基于海量品牌供应商资源标准化<br/>输出的品类及活动场景，提供综<br/>合运营工具，支持目标用户群精<br/>细化运营。</p>
          </li>
          <li>
            <h3>高频消费场景</h3>
            <p>通过嵌入高频场景配合权益，引<br/>导用户将日常高频行为留存平台<br/>内，提升用户活跃与粘性</p>
          </li>
          <li>
            <h3>营销工具库加持</h3>
            <p>丰富的游戏化营销工具库，根据<br/>不同用户运营目标快速配置营销<br/>活动，针对性提升用户拉新、活<br/>跃、转化。</p>
          </li>
          <li>
            <h3>用户数据分析</h3>
            <p>根据用户行为标签系统及运营活<br/>动数据收集，完善用户画像并优<br/>化营销活动策略，不断提升精准<br/>营销和活动效果。</p>
          </li>
          <li>
            <h3>多维营销数据统计</h3>
            <p>活动效果复盘，验证场景选择-<br/>权益激励-用户属性的准确性，<br/>为下次活动策划及包装提供有力<br/>的精准数据依据。</p>
          </li>
        </ul>
      </div>      
    </div>

     <div class="f4">
      <div class="f4-content">
          <div class="title">
            <h2 data-aos="zoom-in">业务范围</h2>
            <p data-aos="zoom-in">围绕“用户经营”打造业务闭环</p>
          </div>
        <ul>
          <li data-aos="fade-right" data-aos-duration="1000">
            <div class="box" >
              <h3>整合权益商品供应链</h3>
              <p>虚拟商品+实物商品<br/>营销礼赠、积分兑换、会员权益、产品商<br/>城、企业福利。</p>
              <a href="javascript:;" @click="linkToProductsServices(4)">了解更多</a>
            </div>
            <img src="../../assets/NewHome/f4-01.png" alt="">
          </li>
          <li data-aos="fade-right">
            <div class="box" >
              <h3>数字场景开发</h3>
              <p>API应用程序标准接口&SaaS权益平台<br/>高频权益场景植入、权益平台功能完整提<br/>供、用户生命周期经营。</p>
              <a href="javascript:;" @click="linkToProductsServices(2)">了解更多</a>
            </div>
            <img src="../../assets/NewHome/f4-02.png" alt="">
          </li>
          <li data-aos="fade-left">
            <div class="box" >
              <h3>营销策划服务</h3>
              <p>模板化活动+定制化活动<br/>用户分析，活动策划，游戏化工具，行为标<br/>签，结果导向。</p>
              <a href="javascript:;" @click="linkToProductsServices(1)">了解更多</a>
            </div>
            <img src="../../assets/NewHome/f4-03.png" alt="">
          </li>
          <li data-aos="fade-left" data-aos-duration="1000">
            <div class="box" >
              <h3>平台流量运营</h3>
              <p>助力流量资产化<br/>品牌联动、商品运营、活动运营、数据营<br/>销、整合变现。</p>
              <a href="javascript:;" @click="linkToProductsServices(3)">了解更多</a>
            </div>
            <img src="../../assets/NewHome/f4-04.png" alt="">
          </li>
        </ul>
      </div>      
    </div>

    <div class="f6">
      <div class="f6-content">
        <div class="title">
        <h2 data-aos="zoom-in">合作案例</h2>
        <p data-aos="zoom-in">累计服务300+泛金融行业客户合作</p>
        <p data-aos="zoom-in">提供金融企业级的专业场景营销服务</p>
      </div>
      <ul>
        <li data-aos="fade-down-right">
          <div class="box">
            <h3>数字化营销</h3>
            <p>光大银行 邮储银行 华夏银行...</p>
            <div class="btn">
              <a href="javascript:;">场景经营</a>
              <a href="javascript:;">用户经营</a>
              <a href="javascript:;">VIP客户经营</a>
            </div>
          </div>
          <img src="../../assets/NewHome/f6-01.png" alt="">
        </li>
        <li data-aos="fade-down">
          <div class="box">
            <h3>企业福利平台搭建</h3>
            <p>中国商飞 太平洋在线...</p>
            <div class="btn">
              <a href="javascript:;">企业福利</a>
              <a href="javascript:;">权益平台</a>
              <a href="javascript:;">定制化权益卡</a>
            </div>
          </div>
          <img src="../../assets/NewHome/f6-02.png" alt="">
        </li>
        <li data-aos="fade-down-left">
          <div class="box" >
            <h3>用户经营平台搭建</h3>
            <p>浙江邮储、东莞银行积分商城、太平洋在线...</p>
            <div class="btn">
              <a href="javascript:;">场景经营</a>
              <a href="javascript:;">用户经营</a>
              <a href="javascript:;">VIP客户经营</a>
            </div>
          </div>
          <img src="../../assets/NewHome/f6-03.png" alt="">
        </li>
        <li data-aos="fade-up-right">
          <div class="box">
            <h3>场景流量运营</h3>
            <p>山东建行、中信银行、广东中银...</p>
            <div class="btn">
              <a href="javascript:;">流量运营</a>
              <a href="javascript:;">细分客群</a>
              <a href="javascript:;">精准营销</a>
            </div>
          </div>
          <img src="../../assets/NewHome/f6-04.png" alt="">
        </li>
        <li data-aos="fade-up">
          <div class="box">
            <h3>品牌联合营销</h3>
            <p>光大银行 农业银行 浦发银行 中信银行...</p>
            <div class="btn">
              <a href="javascript:;">品牌联动</a>
              <a href="javascript:;">收银台推广</a>
              <a href="javascript:;">支付立减</a>
              <a href="javascript:;">会员共享</a>
            </div>
          </div>
          <img src="../../assets/NewHome/f6-05.png" alt="">
        </li>
        <li data-aos="fade-up-left">
          <div class="box">
            <h3>卡产品策划</h3>
            <p>中国银联 JCB...</p>
            <div class="btn">
              <a href="javascript:;">产品策划</a>
              <a href="javascript:;">设计执行</a>
              <a href="javascript:;">品牌合作</a>
            </div>
          </div>
          <img src="../../assets/NewHome/f6-06.png" alt="">
        </li>
      </ul>
      </div>      
    </div>


    <div class="f7">
      <div class="f7-content">
        <div class="title" data-aos="slide-up">
          <p data-aos="slide-up">合作伙伴</p>
        </div>
        <img data-aos="zoom-in" src="../../assets/home/f7-2.png" alt="">
      </div>      
    </div>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "../../../node_modules/vue-awesome-swiper/node_modules/swiper/dist/css/swiper.min.css"
  import countTo from 'vue-count-to';
  export default {
  components: {
    swiper,
    swiperSlide,
    countTo
  },
  data() {
    return {
      swiperData:[
        {
          img:require("../../assets/home/f2-1.jpg"),
          text:"洞察"
        },
        {
          img:require("../../assets/home/f2-2.jpg"),
          text:"触达"
        },
        {
          img:require("../../assets/home/f2-3.jpg"),
          text:"赋能"
        },
        {
          img:require("../../assets/home/f2-4.jpg"),
          text:"效率"
        },
        {
          img:require("../../assets/home/f2-5.jpg"),
          text:"整合"
        },
      ],
      swiperTextActive:0,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        on: {
          slideChange:() => {
            if(this.swiper.activeIndex>this.swiperData.length){
              this.swiperTextActive=0
            }else if(this.swiper.activeIndex<=0){
              this.swiperTextActive = this.swiperData.length-1;
            }
            else{
              this.swiperTextActive = this.swiper.activeIndex-1;
            }
          }
        }
      },
      f5Show:0
    };
  },
  computed: {
    swiper() {      
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
  },
  methods: {
    changeSlideCur(idx){
      this.swiper.activeIndex=idx+1
      this.swiperTextActive = idx
    },
    mouseoverFun(parmas){
      this.f5Show=parmas;
    },
    mouseLeaveFun(){
       this.f5Show=0
    },
    linkToProductsServices(parmas){
      this.$store.commit('changeNavCur', 1);
      this.$router.push({ name: 'ProductsServices', params: { to: parmas }})
      window,scrollTo(0,0)
    }
  },
};
</script>

<style src="./NewHome.scss" lang="scss" scoped></style>