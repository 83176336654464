import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
Vue.prototype.$axios = axios 

// 引入bootstrap-vue
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
// import '../node_modules/bootstrap/dist/css/bootstrap.css'
// import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'


// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )

if (process.env.NODE_ENV === "development") {
  console.log('[ 开发环境 ]');
} else {
  console.log = ()=>{}
}

//aos.js动画
import "../node_modules/aos/dist/aos.css";
import AOS from "../node_modules/aos/dist/aos.js";
AOS.init({
  duration: 500,
});

//reset.css
import './assets/reset.css'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.afterEach((to, from) => {
  if (from.name == "ProductsServices" || from.name == "Solution"){//离开这两个页面后 重置锚点
    store.commit('changeProductsServicesTo', 0);
    store.commit('changeSolutionTo', 0);
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
