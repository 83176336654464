import Vue from 'vue'
import VuexPersistence from 'vuex-persist'
import Vuex from 'vuex'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  state: {
    navList: [{
        name: "首页",
        link: "NewHome"
      },
      {
        name: "产品与服务",
        link: "ProductsServices"
      },
      {
        name: "解决方案",
        link: "Solution"
      },
      {
        name: "合作联系",
        link: "Cooperation"
      },
      {
        name: "关于我们",
        link: "About"
      }
    ],
    navCur: 0,
    ProductsServicesTo: 0, //监听ProductsServices页面点击footer锚点
    SolutionTo: 0 //监听Solution页面点击footer锚点
  },
  mutations: {
    changeNavCur(state, params) {
      state.navCur = params
    },
    changeProductsServicesTo(state, params) {
      state.ProductsServicesTo = params
    },
    changeSolutionTo(state, params) {
      state.SolutionTo = params
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})