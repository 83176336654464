<template>
  <div class="home">
    <div class="f1 w-1400px">
      <div class="text0">
        <div class="text text1">
          <h1>山川形胜 金城千里</h1>
          <p>足以控四夷，制天下</p>
        </div>
        <div class="text text2">
          <h1>数字场景权益服务商</h1>
          <p>助您布局商业 智胜天下</p>
        </div>
      </div>
    </div>
    <div class="f3" data-aos="slide-up">
      <div class="w-1400px">
        <div class="introductionText">
          <p>金融行业 <strong>供应链 + 场景 + 技术</strong> 的服务公司</p>
          <span>帮助银行与品牌构建金融级流量枢纽  赋能客户深度经营</span>
          <span>帮助品牌最小成本的获得流量支配权</span>
          <span>为企业构建未来数字化护城墙</span>
        </div>
        <div>
        <div class="countWrap">
          <div class="countItem">
            <div class="countNum">
              <countTo ref='countNumRef' :startVal='0' :endVal='10' :duration='3000'></countTo>年+
            </div>
            <div class="countText">行业经验</div>
          </div>
          <div class="countItem">
            <div class="countNum">
              <countTo  ref='countNumRef' :startVal='0' :endVal='10' :duration='3000'></countTo>+
            </div>
            <div class="countText">站路合作</div>
          </div>
          <div class="countItem">
            <div class="countNum">
              <countTo ref='countNumRef' :startVal='0' :endVal='100' :duration='3000'></countTo>亿+
            </div>
            <div class="countText">交易额</div>
          </div>
          <div class="countItem">
            <div class="countNum">
              <countTo ref='countNumRef' :startVal='0' :endVal='1000' :duration='3000'></countTo>+
            </div>
            <div class="countText">品牌供应资料</div>
          </div>
        </div>
        </div>        
      </div>
    </div>
    <div class="companyIntroduc">
      <p data-aos="slide-up">公司简介</p>
      <span data-aos="slide-up">
        十余年行业洞察，在赢端连接银行的消费人群与消费品牌，依托可信赖的技术能力，搭建丰富的权益营销工具和海量权益<br>服务及商品。以“供应链整合输出权益+数字化营销场景开发”为核心，<br>
构建丰富权益数字场景，通过完整架构的权益SaaS平台输出，提供行之有效的数字化场景营销解决方案。<br>
助力企业全面实现数字化升级，实现业务突飞猛进的增长。</span>      
    </div>
    <div class="f2">
       <div class="slideText">
         <div :class="[{cur:swiperTextActive==index},'itemText']" v-for="(item,index) in swiperData" :key="index" @click="changeSlideCur(index)">
           {{item.text}}
         </div>
       </div>
       <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item,index) in swiperData" :key="index" :style="'background: url('+item.img+') no-repeat center; background-size: cover;'">
          <!-- <img :src="item.img" alt="" srcset=""> -->
        </swiper-slide>
      </swiper>
    </div>
    <div class="f4 w-100vw">
      <div class="title" data-aos="slide-up">一站式用户经营解决方案</div>
      <div class="subTitle" data-aos="slide-up">促进企业私域流量精细化、数字化、智能化经营，并通过数字化营销工具持续在公域流量开拓增量市场。</div>
      <div class="main">
        <div class="left">
          <img class="f4-1" data-aos="fade-left" src="../../assets/home/f4-1.png" alt="">
          <img class="f4-2" data-aos="fade-left" aos-delay="250" src="../../assets/home/f4-2.png" alt="">
        </div>
        <img class="rightArrow rightArrow1" data-aos="fade-left" aos-delay="500" src="../../assets/home/rightArrow.png" alt="">
        <img class="rightArrow rightArrow2" data-aos="fade-left" aos-delay="500" src="../../assets/home/rightArrow.png" alt="">
        <div class="blockPanel blockPanel1" data-aos="fade-right"  aos-delay="500">
          <h3>存量市场</h3>
          <p>精细化运营</p>
          <p>持续营销促活</p>
        </div>
        <div class="blockPanel blockPanel2" data-aos="fade-right" aos-delay="500">
          <h3>增量市场</h3>
          <p>拉新裂变</p>
          <p>增加用户触点</p>
          <p>高价值权益引入</p>
          <p>品牌联合营销</p>
          <p>电销&客户经理推介</p>
        </div>
      </div>
    </div>
    <div class="f5 w-1400px">
      <div class="title">
        <p data-aos="slide-up">业务范围</p>
        <span data-aos="slide-up">围绕“用户经营“打造业务闭环</span>
      </div>
      <ul>
        <li @mouseover="mouseoverFun(1)" @mouseleave="mouseLeaveFun"  data-aos="fade-right">
          <div class="itemA">
            <img src="../../assets/home/F5-1A.png" alt="">
            <p>权益商品供应</p>
            <span>1000+品牌虚拟商品资源</span>
            <span>覆盖12大场景实物商品资源</span>
          </div>
          <div class="itemB" v-show="f5Show==1">
            <img src="../../assets/home/F5-1B.png" alt="">
            <div class="itemTitle">权益商品供应</div>
            <div class="subTitle">1000+品牌虚拟商品资源<br>覆盖12大场景实物商品资源</div>
            <div class="items">
              <span>营销礼赠</span>
              <span>积分兑换</span>
              <span>会员权益</span>
              <span>产品商城</span>
              <span>企业福利</span>
            </div>
            <a href="javascript:;">了解详情  &#x3E;</a>
          </div>
        </li>
        <li @mouseover="mouseoverFun(2)" @mouseleave="mouseLeaveFun" data-aos="fade-right">
          <div class="itemA">
            <img src="../../assets/home/F5-2A.png" alt="">
            <p>策划营销服务</p>
            <span>游戏化模板工具</span>
            <span>定制化活动开发</span>
          </div>
          <div class="itemB" v-show="f5Show==2">
            <img src="../../assets/home/F5-2B.png" alt="">
            <div class="itemTitle">策划营销服务</div>
            <div class="subTitle">游戏化模板工具<br>定制化活动开发</div>
            <div class="items">
              <span>用户分析</span>
              <span>活动策划</span>
              <span>游戏化工具</span>
              <span>行为标签</span>
              <span>结果导向</span>
            </div>
            <a href="javascript:;">了解详情  &#x3E;</a>
          </div>
        </li>
        <li @mouseover="mouseoverFun(3)" @mouseleave="mouseLeaveFun" data-aos="fade-left">
          <div class="itemA">
            <img src="../../assets/home/F5-3A.png" alt="">
            <p>数字场景开发</p>
            <span>API应用程序标准接口</span>
            <span>SaaS权益平台快速部署</span>
          </div>
          <div class="itemB" v-show="f5Show==3">
            <img src="../../assets/home/F5-3B.png" alt="">
            <div class="itemTitle">数字场景开发</div>
            <div class="subTitle">游戏化模API应用程序标准接口<br>SaaS权益平台快速部署</div>
            <div class="items">
              <span>权益场景植入</span>
              <span>权益平台提供</span>
              <span>用户经营</span>
            </div>
            <a href="javascript:;">了解详情  &#x3E;</a>
          </div>
        </li>
        <li @mouseover="mouseoverFun(4)" @mouseleave="mouseLeaveFun" data-aos="fade-left">
          <div class="itemA">
            <img src="../../assets/home/F5-4A.png" alt="">
            <p>平台流量运营</p>
            <span>用户精准营销</span>
            <span>助力流量资产化</span>
          </div>
          <div class="itemB" v-show="f5Show==4">
            <img src="../../assets/home/F5-4B.png" alt="">
            <div class="itemTitle">平台流量运营</div>
            <div class="subTitle">用户精准营销<br>助力流量资产化</div>
            <div class="items">
              <span>品牌联动</span>
              <span>商品运营</span>
              <span>活动运营</span>
              <span>数据营销</span>
              <span>整合变现</span>
            </div>
            <a href="javascript:;">了解详情  &#x3E;</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="f6">
      <div class="title">
        <p data-aos="slide-up">合作案例</p>
        <span data-aos="slide-up">累计服务300+泛金融行业客户合作，提供金融企业级的专业场景营销服务</span>
      </div>
      <ul>
        <li data-aos="fade-right">
          <img src="../../assets/home/f6-2-1.png" alt="">
          <div class="itemTitle">数字化营销类</div>
          <div class="itemSubTitle">光大银行、邮储银行、华夏银行...</div>
          <p>策划开发创新型、趣味性的营销活动，结合营销礼品进行用户的拉新、促活、留存。</p>
          <div class="tag">
            <span>场景经营</span>
            <span>用户经营</span>
            <span>VIP客户经营</span>
          </div>
        </li>
        <li data-aos="slide-up">
          <img src="../../assets/home/f6-2-2.png" alt="">
          <div class="itemTitle">企业福利平台搭建</div>
          <div class="itemSubTitle">中国商飞、太平洋在线...</div>
          <p>根据企业属性搭建企业福利精选商城，设计定制化“码上有福利”卡片，提高员工满意度。</p>
          <div class="tag">
            <span>企业福利</span>
            <span>权益平台</span>
            <span>定制化权益卡</span>
          </div>
        </li>
        <li data-aos="fade-left">
          <img src="../../assets/home/f6-2-3.png" alt="">
          <div class="itemTitle">用户经营平台搭建</div>
          <div class="itemSubTitle">浙江邮储、东莞银行积分商城、太平洋在线</div>
          <p>以积分为载体，通过对接电商平台、实体商户等方式，实现平台与客户消费场景的对接。</p>
          <div class="tag">
            <span>定制化积分商城</span>
            <span>积分自主分发</span>
            <span>营销策划运营</span>
          </div>
        </li>
        <li data-aos="fade-right">
          <img src="../../assets/home/f6-2-4.png" alt="">
          <div class="itemTitle">场景流量运营</div>
          <div class="itemSubTitle">山东建行、中信银行、广东中银...</div>
          <p>针对目标用户，通过设计、选品、策划等营销手段，持续运营客户。</p>
          <div class="tag">
            <span>流量运营</span>
            <span>细分客群</span>
            <span>精准营销</span>
          </div>
        </li>
        <li data-aos="slide-up">
          <img src="../../assets/home/f6-2-5.png" alt="">
          <div class="itemTitle">品牌联合营销</div>
          <div class="itemSubTitle">光大、农行、浦发、中信卡中心</div>
          <p>联动线下商户品牌，策划执行营销活动，拉新信用卡用户，并促进用户刷卡消费。</p>
          <div class="tag">
            <span>品牌联动</span>
            <span>收银台推广</span>
            <span>支付立减  会员共享</span>
          </div>
        </li>
        <li data-aos="fade-left">
          <img src="../../assets/home/f6-2-6.png" alt="">
          <div class="itemTitle">卡产品策划</div>
          <div class="itemSubTitle">中国银联、JCB</div>
          <p>卡产品策划、卡面设计、卡权益设计与提供。联合品牌方进行权益和服务资源的优选输出。</p>
          <div class="tag">
            <span>卡面设计</span>
            <span>产品策划</span>
            <span>品牌合作</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="f7">
      <div class="title" data-aos="slide-up">
        <p data-aos="slide-up">合作伙伴</p>
      </div>
      <img data-aos="slide-up" src="../../assets/home/f7-2.png" alt="">
    </div>
  </div>
</template>

<script>
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "../../../node_modules/vue-awesome-swiper/node_modules/swiper/dist/css/swiper.min.css"
  import countTo from 'vue-count-to';
  export default {
  components: {
    swiper,
    swiperSlide,
    countTo
  },
  data() {
    return {
      swiperData:[
        {
          img:require("../../assets/home/f2-1.jpg"),
          text:"洞察"
        },
        {
          img:require("../../assets/home/f2-2.jpg"),
          text:"触达"
        },
        {
          img:require("../../assets/home/f2-3.jpg"),
          text:"赋能"
        },
        {
          img:require("../../assets/home/f2-4.jpg"),
          text:"效率"
        },
        {
          img:require("../../assets/home/f2-5.jpg"),
          text:"整合"
        },
      ],
      swiperTextActive:0,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        on: {
          slideChange:() => {
            if(this.swiper.activeIndex>this.swiperData.length){
              this.swiperTextActive=0
            }else if(this.swiper.activeIndex<=0){
              this.swiperTextActive = this.swiperData.length-1;
            }
            else{
              this.swiperTextActive = this.swiper.activeIndex-1;
            }
          }
        }
      },
      f5Show:0
    };
  },
  computed: {
    swiper() {      
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
  },
  methods: {
    changeSlideCur(idx){
      this.swiper.activeIndex=idx+1
      this.swiperTextActive = idx
    },
    mouseoverFun(parmas){
      this.f5Show=parmas;
    },
    mouseLeaveFun(){
       this.f5Show=0
    },
  },
};
</script>

<style src="./Home.scss" lang="scss" scoped></style>